<template>
  <div class="content">
    <img class="posterbg"
         src="@/assets/abt/img/posterbg.png"
         alt="" />
    <!-- v-if='item.phttp' -->
    <swiper :options="swiperOption"
            ref="mySwiper"
            class="swiper">
      <swiper-slide v-for="(item, index) in Lists"
                    :key="index">
        <img :src="ossurl+item.pichttp"
             class="img-cover">
      </swiper-slide>
    </swiper>
    <div class="btn">
      <div class="cardBtn"
           :style="`backgroundColor:${colorprimary}`"
           @click="createPoster()">生成天气海报</div>
      <div class="cardBtn"
           :style="`backgroundColor:${colorprimary}`"
           @click="showPoster">海报库</div>
    </div>
    <md-landscape v-model="showFullScreen"
                  full-screen>
      <div class="header">
        <span>海报库</span>
      </div>
      <md-scroll-view ref="scrollView"
                      v-show="!isNull"
                      auto-reflow
                      :scrolling-x="false"
                      @end-reached="loadMore">
        <div class="imgList">
          <div class="poster"
               v-for="(item,index) in imgLists"
               :key="index">
            <img class="img"
                 :src="ossurl+item.pichttp" />
            <!-- <span>{{item.}}</span> -->
            <div class="imgbtn"
                 :style="`border:1px solid ${colorprimary};color:${colorprimary}`"
                 @click="use(item.sno)">点击使用</div>
          </div>
        </div>
        <md-scroll-view-more slot="more"
                             :is-finished="loading" />
      </md-scroll-view>
      <div class="nodata"
           v-show="isNull">
        <img class="kongimg"
             src="@/assets/image/null.png"
             alt="" />
      </div>
    </md-landscape>
    <md-landscape v-model="showPic">
      <img :src="sctp">
    </md-landscape>
  </div>
</template>
<script>
import { selectPage, useHB, DoRecord } from '@/api/abt/customerOperation/common'
import { getWeather } from '@/api/abt/customerOperation/weatherposter'
import { getStorage } from '@/lib/util'
import { getConfig, getlocation } from '@/lib/wxapi'
import config from '@/config'
import { Toast } from 'mand-mobile'
import loadMore from '@/mixins/loadmore'
export default {
  mixins: [loadMore],
  data () {
    return {
      ossurl: '',
      user: '',
      pageNo: 1,
      size: 9,
      total: 0,
      totalPage: 0,
      loading: false,
      swiperOption: {
        // swiper configs 所有的配置同swiper官方api配置
        // autoplay: 3000,
        centeredSlides: true,
        // direction: 'vertical',
        slidesPerView: 1.5,
        // spaceBetween: 60,
        loop: false,
        // autoplayDisableOnInteraction:false,
        // speed:2000,
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 0,
          stretch: -100,
          depth: 300,
          modifier: 1,
          slideShadows: false
        },
        autoplay: false,
        on: {}
      },
      Lists: [],
      showFullScreen: false,
      imgLists: [],
      showPic: false,
      sctp: '',
      weather: {
        date: '2019-11-18 星期一',
        highTem: '14℃',
        location: '郑州市 金水区',
        lowTem: '-1℃',
        tagcode: 'NRABTPT1547188319',
        tem: '4℃',
        temp: '14℃/-1℃'
      },
      isNull: true,
      height: '',
      width: '',
      colorprimary: ''
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.user = getStorage('u_s', {})
    this.colorprimary = getStorage('theme', '')
    this.height = document.body.clientHeight
    this.width = document.body.clientWidth
    this.getWxweather()
    // this.getWeather()
  },
  mounted () {
    document.querySelector('.content').style.height = `${this.height}px`
    document.querySelector('.content').style.width = `${this.width}px`
    let path = ''
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // ios
      if (this.$route.query.frompage == 'source') {
        path = config.redirect_uri + '/source'
      } else {
        path = config.redirect_uri
      }
    } else {
      // android
      path = window.location.href
    }
    getConfig(path)
  },
  methods: {
    getWxweather () {
      getlocation().then(res => {
        console.log(res)
        // let lat = res.latitude + 0.004880706848
        // let lng = res.longitude + 0.012723672143
        let lat = res.latitude
        let lng = res.longitude
        this.getWeather(lat, lng)
      })
    },
    // 根据经纬度获取天气 getWeather
    getWeather (lat, lng) {
      let data = {
        // lat: 34.804650706848, // 纬度
        // lng: 113.673443672143// 经度
        lat: lat, // 纬度
        lng: lng// 经度
      }
      getWeather(data).then(res => {
        this.weather = res.data.data
        this.getlist()
      })
    },
    showPoster () {
      this.showFullScreen = true
      this.getData()
    },
    use (val) {
      Toast.loading('生成中...')
      this.sctp = ''
      useHB({ sno: val, location: this.weather.location, date: this.weather.date, temp: this.weather.temp, flag: '3', rytype: this.user.rytype }).then(res => {
        this.sctp = this.ossurl + res.data.data.url
        this.showPic = true
        DoRecord({
          userid: this.user.userid,
          suserid: this.user.userid,
          empno: this.user.empno,
          rytype: this.user.rytype,
          otype: '3',
          sno: res.data.data.sno,
          osno: res.data.data.osno,
          btagcode: '3'
        }).then(res => { })
        Toast.hide()
      })
    },
    createPoster () {
      let sno = this.Lists[this.$refs.mySwiper.swiper.activeIndex].sno
      Toast.loading('生成中...')
      this.sctp = ''
      useHB({ sno: sno, location: this.weather.location, date: this.weather.date, temp: this.weather.temp, flag: '3', rytype: this.user.rytype }).then(res => {
        this.sctp = this.ossurl + res.data.data.url
        this.showPic = true
        DoRecord({
          userid: this.user.userid,
          suserid: this.user.userid,
          empno: this.user.empno,
          rytype: this.user.rytype,
          stagcode: this.weather.tagcode,
          otype: '3',
          sno: res.data.data.sno,
          osno: res.data.data.osno,
          btagcode: '3'
        }).then(res => { })
        Toast.hide()
      })
    },
    getlist () {
      let data = {
        page: 1,
        size: 100,
        flag: '0',
        btagcode: '3',
        stagcode: this.weather.tagcode
      }
      selectPage(data).then(res => {
        this.Lists = res.data.data.rows
      })
    },
    async getData (isInit = true) {
      let { pageNo, size } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let data = {
        page: this.pageNo,
        size,
        btagcode: '3',
        flag: '0',
        stagcode: this.stagcode
      }
      let res = await selectPage(data)
      this.imgLists = isInit ? res.data.data.rows : this.imgLists.concat(res.data.data.rows)
      if (this.imgLists.length == 0) {
        this.isNull = true
      } else {
        this.isNull = false
      }
      this.total = res.data.data.total
      this.totalPage = res.data.data.totalpage
      return res
    }
  }
}
</script>
<style lang="stylus" scoped>
.posterbg {
  width: 100%;
  height: 100%;
  display: block;
}

.header {
  width: 100%;
  height: 130px;
  border-bottom: 1px solid #dbdddc;
  text-align: center;
  // line-height: 50px;
  background: #ffffff;
  position: fixed;
  // top: 100px;
  // left: 0;
  z-index: 9;

  span {
    margin-top: 130px;
    line-height: 130px;
    font-size: 40px;
    font-weight: 600;
  }
}

.btn {
  text-align: center;
  position: fixed;
  left: 0;
  top: 85%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  width: 100%;

  .cardBtn {
    width: 350px;
    padding: 20px 30px;
    // background-color: color-primary;
    color: #fff;
    border-radius: 10px;
    font-size: 40px;
  }
}

.swiper {
  position: absolute;
  left: 0;
  top: 10%;
  width: 100%;
  height: 70%;
  background-position: center;
}

.img-cover {
  width: 100%;
  // height 100%
  display: block;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.imgList {
  padding-top: 150px;
  display: flex;
  flex-wrap: wrap;
}

.poster {
  width: 33%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  .img {
    width: 100%;
    height: 500px;
  }

  .imgbtn {
    width: 200px;
    margin: 10px auto;
    padding: 10px 30px;
    // color: color-primary;
    // border: 1px solid color-primary;
    border-radius: 10px;
    font-size: 30px;
    // width 200px
    // height 80px
    // margin 10px auto
    // @click="use(item.sno)"
  }
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}

/deep/.md-landscape-content {
  width: 740px;
}
</style>
