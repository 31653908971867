/*
 * @Author: 黄孝娟
 * @Date: 2019-11-08 14:48:19
 * @LastEditors: 黄孝娟
 * @LastEditTime: 2019-11-08 11:48:06
 */

import axios from '@/lib/api.request'

// 根据经纬度获取天气 /abt/abtComKjLibw/getWeather
export const getWeather = data =>
  axios.post('/abt/abtComKjLibw/getWeather', data)
